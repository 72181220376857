<template>
    <div>
        <v-row class="ma-0 mb-lg-6 mb-xl-6" align="center" justify="center" v-if="model.logo">
            <v-img :src="model.logo" contain height="160">
                <template v-slot:placeholder>
                    <v-row class="ma-0 w-100 h-100" align="center" justify="center" style="height: 160px">
                        <div class="spinner-design-loader"></div>
                    </v-row>
                </template>
            </v-img>
        </v-row>
        <v-divider class="black"/>
        <v-row class="ma-0" justify-sm="end">
            <v-col cols="12" md="4" xl="4" lg="4" align="center" class="text-lg-h6 text-xl-h6 text-md-h6 font-weight-bold">
                <span class="mr-2">Phone:</span>{{ model.phone.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3') || '-' }}
            </v-col>
            <v-col cols="12" md="4" xl="4" lg="4"  align="center" class="text-lg-h6 text-xl-h6 text-md-h6  font-weight-bold">
                <div class="text-capitalize" v-if="model.address">{{ model.address}}</div>
                <div v-if="model.email">{{ model.email}}</div>
            </v-col>
            <v-col cols="12" md="4" xl="4" lg="4" align="center" class="text-lg-h6 text-xl-h6 text-md-h6 font-weight-bold">
                <span class="mr-2">ABN:</span>{{ model.abn.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3') || '-' }}
            </v-col>
        </v-row>
        <v-divider class="black mb-5"/>
    </div>
</template>

<script>
export default {
    props: ['model']
}
</script>